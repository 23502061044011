/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EnvConfig } from '@/common/env-config';

import * as Pages from '@/router/pages';

export * from '@/router/pages'; // pagesの再export
export const routes = [
  {
    path: '/',
    redirect: '/select-store',
  },
  {
    path: Pages.LOGIN.route,
    component: () => import('@/Login.vue'),
    name: Pages.LOGIN.name,
    meta: {
      title: `${Pages.LOGIN.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: false,
    },
  },
  {
    path: Pages.SELECT_SHOP.route,
    component: () => import('@/pages/SelectStore/Main.vue'),
    name: Pages.SELECT_SHOP.name,
    meta: {
      title: `${Pages.SELECT_SHOP.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.HOME.route,
    component: () => import('@/pages/Home/Main.vue'),
    name: Pages.HOME.name,
    meta: {
      title: `${Pages.HOME.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.TABLES.route,
    component: () => import('@/pages/Tables/Main.vue'),
    name: Pages.TABLES.name,
    meta: {
      title: `${Pages.TABLES.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.SHIPPING.route,
    component: () => import('@/pages/Shipping/Main.vue'),
    name: Pages.SHIPPING.name,
    meta: {
      title: `${Pages.SHIPPING.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.ORDER_TICKET_LIST.route,
    component: () => import('@/pages/OrderTicketList/Main.vue'),
    name: Pages.ORDER_TICKET_LIST.name,
    meta: {
      title: `${Pages.ORDER_TICKET_LIST.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.TRANSACTION_HISTORY.route,
    component: () => import('@/pages/TransactionHistory/Main.vue'),
    name: Pages.TRANSACTION_HISTORY.name,
    meta: {
      title: `${Pages.TRANSACTION_HISTORY.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.RECEIPT_JOURNAL.route,
    component: () => import('@/pages/ReceiptJournals/Main.vue'),
    name: Pages.RECEIPT_JOURNAL.name,
    meta: {
      title: `${Pages.RECEIPT_JOURNAL.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.CUSTOMER_INFO.route,
    component: () => import('@/pages/CustomerInfo/Main.vue'),
    name: Pages.CUSTOMER_INFO.name,
    meta: {
      title: `${Pages.CUSTOMER_INFO.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.WAITING_RECEPTION.route,
    component: () => import('@/pages/WaitingReception/Main.vue'),
    name: Pages.WAITING_RECEPTION.name,
    meta: {
      title: `${Pages.WAITING_RECEPTION.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.WAITING_STORE_RECEPTION.route,
    component: () => import('@/pages/WaitingStoreReception/Main.vue'),
    name: Pages.WAITING_STORE_RECEPTION.name,
    meta: {
      title: `${Pages.WAITING_STORE_RECEPTION.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: false,
    },
  },
  {
    path: Pages.WAITING_RECEPTION_STATUS.route,
    component: () => import('@/pages/WaitingReceptionStatus/Main.vue'),
    name: Pages.WAITING_RECEPTION_STATUS.name,
    meta: {
      title: `${Pages.WAITING_RECEPTION_STATUS.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: false,
    },
  },
  {
    path: Pages.CUSTOMER_QRCODE_READER.route,
    component: () => import('@/pages/CustomerQrcodeReader/Main.vue'),
    name: Pages.CUSTOMER_QRCODE_READER.name,
    meta: {
      title: `${Pages.CUSTOMER_QRCODE_READER.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: false,
    },
  },
  {
    path: Pages.MEDIA_MANAGED.route,
    component: () => import('@/pages/MediaManaged/Main.vue'),
    name: Pages.MEDIA_MANAGED.name,
    meta: {
      title: `${Pages.MEDIA_MANAGED.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.MESSAGE_LIST.route,
    component: () => import('@/pages/MessageLog/Main.vue'),
    name: Pages.MESSAGE_LIST.name,
    meta: {
      title: `${Pages.MESSAGE_LIST.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.MENU.route,
    component: () => import('@/pages/MenuInfo/Main.vue'),
    name: Pages.MENU.name,
    meta: {
      title: `${Pages.MENU.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.MESSAGE_AUTOMATION.route,
    component: () => import('@/pages/MessageAutomation/Main.vue'),
    name: Pages.MESSAGE_AUTOMATION.name,
    meta: {
      title: `${Pages.MESSAGE_AUTOMATION.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.QUESTIONNAIRE.route,
    component: () => import('@/pages/QuestionnaireInfo/Main.vue'),
    name: Pages.QUESTIONNAIRE.name,
    meta: {
      title: `${Pages.QUESTIONNAIRE.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.MEMBERS_RANK.route,
    component: () => import('@/pages/MembersRankInfo/Main.vue'),
    name: Pages.MEMBERS_RANK.name,
    meta: {
      title: `${Pages.MEMBERS_RANK.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.COUPONS.route,
    component: () => import('@/pages/Coupons/Main.vue'),
    name: Pages.COUPONS.name,
    meta: {
      title: `${Pages.COUPONS.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.STAFFS.route,
    component: () => import('@/pages/Staffs/Main.vue'),
    name: Pages.STAFFS.name,
    meta: {
      title: `${Pages.STAFFS.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.RESTAURAN_INFO.route,
    component: () => import('@/pages/RestaurantInfo/Main.vue'),
    name: Pages.RESTAURAN_INFO.name,
    meta: {
      title: `${Pages.RESTAURAN_INFO.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.COMMON_INFO.route,
    component: () => import('@/pages/CommonInfo/Main.vue'),
    name: Pages.COMMON_INFO.name,
    meta: {
      title: `${Pages.COMMON_INFO.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.STORE_INFO.route,
    component: () => import('@/pages/StoreInfo/Main.vue'),
    name: Pages.STORE_INFO.name,
    meta: {
      title: `${Pages.STORE_INFO.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: true,
    },
  },
  {
    path: Pages.USER_ORDER.route,
    component: () => import('@/pages/UserOrder/Main.vue'),
    name: Pages.USER_ORDER.name,
    meta: {
      title: Pages.USER_ORDER.name,
      requiresAuth: false,
      viewportFit: true,
      // forceLogout: true,
    },
  },
  {
    path: Pages.USER_ANYWHERE_ORDER.route,
    component: () => import('@/pages/UserOrder/MainAnywhere.vue'),
    name: Pages.USER_ANYWHERE_ORDER.name,
    meta: {
      title: Pages.USER_ANYWHERE_ORDER.name,
      requiresAuth: false,
      viewportFit: true,
      // forceLogout: true,
    },
  },
  {
    path: Pages.USER_ANYWHERE_ORDER_SELECT_STORE.route,
    component: () => import('@/pages/UserOrder/MainAnywhereSelectStore.vue'),
    name: Pages.USER_ANYWHERE_ORDER_SELECT_STORE.name,
    meta: {
      title: Pages.USER_ANYWHERE_ORDER_SELECT_STORE.name,
      requiresAuth: false,
      viewportFit: true,
    },
  },
  {
    path: Pages.USER_WAITING.route,
    component: () => import('@/pages/UserWaiting/Main.vue'),
    name: Pages.USER_WAITING.name,
    meta: {
      title: Pages.USER_WAITING.name,
      requiresAuth: false,
      viewportFit: true,
      forceLogout: true,
    },
  },
  {
    path: Pages.USER_EC_ORDER.route,
    component: () => import('@/pages/UserOrder/MainEc.vue'),
    name: Pages.USER_EC_ORDER.name,
    meta: {
      title: Pages.USER_EC_ORDER.name,
      requiresAuth: false,
      viewportFit: true,
      // forceLogout: true,
    },
  },
  {
    path: Pages.USER_MEMBERSHIP.route,
    component: () => import('@/pages/UserMembership/Main.vue'),
    name: Pages.USER_MEMBERSHIP.name,
    meta: {
      title: Pages.USER_MEMBERSHIP.name,
      requiresAuth: false,
      viewportFit: true,
      forceLogout: true,
    },
  },
  // エントリーフォーム
  {
    path: Pages.ENTRY_FORM.route,
    component: () => import('@/pages/Entry/EntryForm.vue'),
    meta: {
      title: `${Pages.ENTRY_FORM.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: false,
    },
  },
  {
    path: Pages.PAYMENT_FORM.route,
    component: () => import('@/pages/Entry/PaymentForm.vue'),
    meta: {
      title: `${Pages.PAYMENT_FORM.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: false,
    },
  },
  {
    path: Pages.ENTRY_DONE.route,
    component: () => import('@/pages/Entry/EntryDone.vue'),
    meta: {
      title: `${Pages.ENTRY_DONE.name} | ${EnvConfig.app.VUE_APP_TITLE}`,
      requiresAuth: false,
    },
  },
  {
    path: Pages.ACCEPT_REDIRECT.route,
    component: () => import('@/pages/UserOrder/AcceptRedirect.vue'),
    name: Pages.ACCEPT_REDIRECT.name,
    meta: {
      title: Pages.ACCEPT_REDIRECT.name,
      requiresAuth: false,
      viewportFit: true,
      // forceLogout: true,
    },
  },
];
