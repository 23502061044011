import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "note" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ToolTip',
  props: {
    icon: {},
    text: {},
    color: {},
    size: {},
    buttonText: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, { location: "top" }, {
    activator: _withCtx(({ props }) => [
      (_ctx.buttonText)
        ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, props, {
            color: _ctx.color,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 2
          }, 1040, ["color"]))
        : (_openBlock(), _createBlock(_component_v_icon, _mergeProps({ key: 1 }, props, {
            color: _ctx.color,
            size: _ctx.size,
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.icon), 1)
            ]),
            _: 2
          }, 1040, ["color", "size"]))
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }))
}
}

})