<template>
  <v-dialog v-model="display" max-width="500px">
    <v-card title="スタッフ選択">
      <v-card-text class="text-center py-1">
        <v-select
          v-model="selectedStaffId"
          :items="selectStaffs"
          item-title="text"
          item-value="value"
          variant="outlined"
          density="compact"
          class="body"
        ></v-select>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-btn block variant="outlined" @click="close">閉じる</v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn block color="primary" variant="elevated" @click="changeStaff">設定</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Staff } from '@/store/models/staff';

// emit
const emit = defineEmits(['changeStaff']);

// Data
const display = ref(false);
const selectedStaffId = ref('');
const selectStaffs = ref<{ text: string; value: string }[]>([{ text: '選択解除', value: '' }]);

// Computed

// Methods
const open = (obj: { staffId: string; staffList: Staff[] }) => {
  display.value = true;
  selectedStaffId.value = obj.staffId;
  selectStaffs.value = [
    ...obj.staffList.map((staff) => ({ text: staff.params.name, value: staff.id })),
    { text: '選択解除', value: '' },
  ];
};

const close = () => {
  display.value = false;
};

const changeStaff = () => {
  emit('changeStaff', selectedStaffId.value);
  close();
};

// Expose
defineExpose({ open, close });

// LifeCycle Hooks
</script>
