import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import { auth } from '@/firebase';
import { docs, storageRef } from '@/firebase/dao';
import { Unsubscribe, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { api } from '@/firebase/api';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  User,
} from 'firebase/auth';
import { getDownloadURL } from 'firebase/storage';
import { removeToken, unregisterSw } from '@/firebase/functions';
import { CancelSubscriptionReq, CancelSubscriptionRes, ConnectPosReq, ConnectPosRes } from '@/firebase/dto';
import { GuestEntryMembershipReq, GuestEntryMembershipRes } from '@/firebase/dto/guest-entry-membership';
import { GuestEntryReq, GuestEntryRes } from '@/firebase/dto/guest-entry';
import { GuestEntryAnywhereRes } from '@/firebase/dto/guest-entry-anywhere';
import { GuestEntryEcRes } from '@/firebase/dto/guest-entry-ec';
import { GuestEntryWaitingReq, GuestEntryWaitingRes } from '@/firebase/dto/guest-entry-waiting';
import {
  AttachMessagingApiReq,
  AttachModuleChannelReq,
  AttachLineRes,
  DetachLineReq,
  DetachLineRes,
} from '@/firebase/dto/line-connect';
import { StripeConnectReq, StripeConnectRes } from '@/firebase/dto/stripe';
import {
  SquareConnectReq,
  SquareConnectRes,
  SquareDisconnectReq,
  SquareDisconnectRes,
  SquareListLocationsReq,
  SquareListLocationsRes,
} from '@/firebase/dto/square';
import { PasswordAuthReq, PasswordAuthRes } from '@/firebase/dto/user-entry';
import { LoignStoreParam, LoignStoreRes } from '@/firebase/dto/store';
import {
  ImportSmaregiTerminalReq,
  ImportSmaregiTerminalRes,
  ImportWaiterTableReq,
  ImportWaiterTableRes,
} from '@/firebase/dto/smaregi';
import { Permission, miniAppPaymentMethods, storeType, useType } from '@/common/appCode';
import { CommonSettings } from '@/common/common-settings';
import { EnvConfig } from '@/common/env-config';
import * as Utils from '@/common/utils';
import { StoreError, DataConflict, ExistRelatedData } from '@/common/error';
import { App } from '@/store/models/app';
import { CApp } from '@/store/models/db/c-app';
import { Store } from '@/store/models/store';
import { Kitchen, LineMiniApp, RSettings, WaitingInfo } from '@/store/models/db/r-settings';
import { Settings, KitchenModel } from '@/store/models/settings';
import { ImageObject, ReceiptJournalItem, StripePrevCardInfo } from '@/store/models/db/common-models';
import { MCustomer, MEcOrderHistory } from '@/store/models/db/log-models';
import { Customer } from '@/store/models/customer';
import { MembersRank } from '@/store/models/members-rank';
import { RMembersRank } from '@/store/models/db/r-members-rank';
import { AccountSetting } from '@/store/models/account-setting';
import { RAccountSetting } from '@/store/models/db/r-account-setting';
import { SquarePaymentInfo } from '@/store/models/db/r-guests';
import { RMenu } from '@/store/models/db/r-menu';
import { useMenuCategoriesStore } from '@/stores/menu-categories';
import { useMenusStore } from '@/stores/menus';
import { useMessagesStore } from '@/stores/messages';
import { useQuestionnairesStore } from '@/stores/questionnaires';
import { useStaffsStore } from '@/stores/staffs';
import { useStoreStore } from '@/stores/store';
import { useTablesStore } from '@/stores/tables';
import { MenuReq, MenuRes } from '@/firebase/dto/get-menu';
import { GmoCardInfo, GmoMulpayInfo, GmoPaymentParams } from '@/store/models/gmo-payment';
import { loadMulpay, MultiPaymentElementStyle } from '@mul-pay/mptoken-js';

export const useUsersStore = defineStore('users', () => {
  const menuCategoriesStore = useMenuCategoriesStore();
  const menusStore = useMenusStore();
  const messagesStore = useMessagesStore();
  const questionnairesStore = useQuestionnairesStore();
  const staffsStore = useStaffsStore();
  const storeStore = useStoreStore();
  const tablesStore = useTablesStore();

  // stateはref変数
  const user = ref(null) as Ref<User | null>;
  const currentStore = ref(null) as Ref<Store | null>;
  const storeId = ref('');
  const email = ref('');
  const bgAnytimeImage = ref('');
  const heroAnytimeImage = ref('');
  const brandLogoImage = ref('');
  const membershipLogoImage = ref('');
  const membershipBackgroundImage = ref('');
  const themeColor = ref(null) as Ref<string | null>;
  const miniAppDarkTheme = ref(false);
  const isLogin = ref(false);
  const settings = ref(new Settings({} as RSettings)) as Ref<Settings>; // 設定
  const userReceiptJournalItem = ref([]) as Ref<ReceiptJournalItem[]>; // 注文履歴
  const ecOrderHistory = ref([]) as Ref<MEcOrderHistory[]>; // 注文履歴
  const useStripePayment = ref(false); // userOrder用
  const useSonyPayment = ref(false); // userOrder用
  const useSonyPaymentCredit = ref(false); // userOrder用
  const useSquarePayment = ref(false);
  const useGmoPayment = ref(false);
  const smaregiMembershipConnected = ref(false); // userOrder用
  const lineMiniApp = ref(null) as Ref<LineMiniApp | null>; // userOrder用
  const publicHoliday = ref({}) as Ref<{ [key: string]: string }>;
  const customer = ref(new Customer({} as MCustomer)) as Ref<Customer>; // 会員情報
  const currentRank = ref(new MembersRank({} as RMembersRank)) as Ref<MembersRank>; // 現在のランク
  const nextRank = ref(new MembersRank({} as RMembersRank)) as Ref<MembersRank>; // 次のランク(最上位のランクの場合は現在のランク)
  const customerTotalAmount = ref(0); // 会員の期間購入金額
  const customerTotalCount = ref(0); // 会員の期間取引回数
  const customerPoint = ref(0); // 会員の期間取引回数
  const app = ref(
    new App({
      version: '0.0.1',
      releaseItems: [],
      notificationItems: [],
    })
  ) as Ref<App>; // アプリ情報
  // 端末権限
  const permission = ref(Permission.admin) as Ref<Permission>;
  const staff = ref('');
  const dbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;
  const appDbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;
  const initAccountTime = ref(null) as Ref<Date | null>;
  const initStoreTime = ref(null) as Ref<Date | null>;
  // アカウント設定
  const accountSettingDbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;
  const accountSetting = ref(new AccountSetting({} as RAccountSetting)) as Ref<AccountSetting>;
  // 多言語情報
  const language = ref('ja');
  const useMultilingual = ref(false);
  const useWaiter = ref(false);
  // Square設定（Web画面使用）
  const squareAppId = ref(EnvConfig.app.SQUARE_CLIENT_ID);
  const squareLocationId = ref() as Ref<string | undefined>;
  const squarePaymentUserInfo = ref() as Ref<SquarePaymentInfo | undefined>;
  const gmoCardInfo = ref() as Ref<GmoCardInfo[] | undefined>;
  const gmoPaymentParams = ref() as Ref<GmoPaymentParams | undefined>;
  const gmoMulpay = ref() as Ref<GmoMulpayInfo | undefined>;
  const stripeConnectAccount = ref() as Ref<string | undefined>;
  const stripePrevCard = ref() as Ref<StripePrevCardInfo | undefined>;

  // gettterはcomputed
  const useComputables = () => {
    return {
      getUser: computed(() => user.value),
      getCurrentStore: computed(() => currentStore.value),
      getStoreId: computed(() => storeId.value),
      getEmail: computed(() => email.value),
      getLogin: computed(() => isLogin.value),
      getStoreLogin: computed(() => (accountSetting.value?.is2ndGenData ? storeId.value !== '' : true)),
      getSettings: computed(() => settings.value),
      getBgAnytimeImage: computed(() => bgAnytimeImage.value),
      getHeroAnytimeImage: computed(() => heroAnytimeImage.value),
      getBrandLogoImage: computed(() => brandLogoImage.value),
      getMembershipLogoImage: computed(() => membershipLogoImage.value),
      getMembershipBackgroundImage: computed(() => membershipBackgroundImage.value),
      getThemeColor: computed(() => themeColor.value),
      isDarkTheme: computed(() => miniAppDarkTheme.value),
      getUserReceiptJournalItem: computed(() => userReceiptJournalItem.value),
      getEcOrderHistory: computed(() => ecOrderHistory.value),
      getApp: computed(() => app.value),
      getInitAccountTime: computed(() => initAccountTime.value),
      getInitStoreTime: computed(() => initStoreTime.value),
      getUseStripePayment: computed(() => prioritize(miniAppPaymentMethods.creditCard)),
      getStripeConnectAccount: computed(() => stripeConnectAccount.value),
      getStripePrevCard: computed(() => stripePrevCard.value),
      getSonyPaymentCredit: computed(() => prioritize(miniAppPaymentMethods.creditCardSp)),
      getUseSquarePayment: computed(() => prioritize(miniAppPaymentMethods.creditCardSquare)),
      getSquareAppId: computed(() => squareAppId.value ?? ''),
      getSquareLocationId: computed(() => squareLocationId.value),
      getSquarePrevCardLast4: computed(() => squarePaymentUserInfo.value?.previousCard.last_4),
      isSmaregiMembershipConnected: computed(() => smaregiMembershipConnected.value),
      getUseSonyPayment: computed(() => prioritize(miniAppPaymentMethods.paypay)),
      getUseGmoPayment: computed(() => prioritize(miniAppPaymentMethods.creditCardGmo)),
      getGmoCardInfo: computed(() => gmoCardInfo.value),
      getGmoPaymentParams: computed(() => gmoPaymentParams.value),
      getLineMiniApp: computed(() => lineMiniApp.value),
      getPublicHoliday: computed(() => publicHoliday.value),
      getLanguage: computed(() => language.value),
      getUseMultilingual: computed(() => useMultilingual.value),
      getUseWaiter: computed(() => useWaiter.value),
      getCustomer: computed(() => customer.value),
      getCustomerPoint: computed(() => customerPoint.value),
      getCurrentRank: computed(() => currentRank.value),
      getNextRank: computed(() => nextRank.value),
      getCustomerTotalAmount: computed(() => customerTotalAmount.value),
      getCustomerTotalCount: computed(() => customerTotalCount.value),
      getPermission: computed(() => permission.value),
      getStaff: computed(() => staff.value),
      getAccountSetting: computed(() => accountSetting.value),
      getStoreType: computed(() => currentStore.value?.type ?? ''),
      getGmoMulpay: computed(() => gmoMulpay.value),
    };
  };
  const computables = useComputables();

  // actionはmutationと統合して、関数

  const setStoreId = (pStoreId: string) => {
    storeId.value = pStoreId;
  };

  /**
   * アカウント情報の初期化
   *
   * @param obj
   * @returns
   */
  const initAccount = async (obj: { user: User }) => {
    user.value = obj.user;
    email.value = obj.user.email ?? '';
    isLogin.value = true;
    permission.value = Utils.getPermission();
    staff.value = localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.SELECT_STAFF) ?? '';

    // AccountSettingを取得
    const accountSettingDoc = await getDoc(docs.accountSettingDoc(obj.user.uid));
    accountSetting.value = new AccountSetting(accountSettingDoc.data() as RAccountSetting);
    if (initAccountTime.value) {
      return;
    }

    // r_account_settingテーブルのリスナーの起動
    startAppSubscribe({
      appId: 'chumon-kun',
    });
    startAccountSettingSubscribe({
      contractId: obj.user.uid,
    });

    initAccountTime.value = new Date();
  };

  /**
   * 店舗情報の初期化
   *
   * @param obj
   * @returns ログイン不可の場合はfalse
   */
  const initStore = async (obj: { user: User | null }): Promise<boolean> => {
    if (!obj.user) {
      return false;
    }
    if (initStoreTime.value) {
      return true;
    }

    // 新データで店舗IDがない場合はログアウト
    const storageStoreId = localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.STORE_ID);
    if (accountSetting.value?.is2ndGenData && !storageStoreId) {
      logout();
      return false;
    }
    // 新データの場合は店舗情報を取得
    if (accountSetting.value?.is2ndGenData && storageStoreId) {
      const store = await storeStore.getStore({ contractId: obj.user.uid, storeId: storageStoreId });
      if (!store) {
        logout();
        return false;
      }
      // 本部店舗でログイン中にセッションが切れて本部店舗IDが無くなった場合はログアウト
      if (
        store.type === storeType.headquarters &&
        !sessionStorage.getItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_STORE_ID)
      ) {
        logout();
        return false;
      }
      storeId.value = store.id;
      currentStore.value = store;
    }

    // r_settingsテーブルのリスナーの起動
    startSettingsSubscribe({
      contractId: obj.user.uid,
      storeId: storeId.value,
    });
    messagesStore.startMessagesSubscribe({
      contractId: obj.user.uid,
    });
    tablesStore.startTablesSubscribe({
      contractId: obj.user.uid,
    });
    questionnairesStore.startQuestionnaireSubscribe({
      contractId: obj.user.uid,
    });
    staffsStore.startStaffSubscribe({ contractId: obj.user.uid });

    initStoreTime.value = new Date();

    return true;
  };

  /**
   * ユーザの初期化
   *
   * @param obj
   */
  const initUserOrder = (obj: { contractId: string; storeId: string; liffAccessToken?: string }) => {
    storeId.value = obj.storeId;
    menusStore.startMenusSubscribe({
      contractId: obj.contractId,
      liffAccessToken: obj.liffAccessToken,
    });
    menuCategoriesStore.startMenuCategoriesSubscribe({
      contractId: obj.contractId,
    });
  };

  /**
   * ユーザの破棄
   *
   * @param commit
   * @param dispatch
   */
  const stopAllSubscription = () => {
    // リスナーの停止
    stopAccountSubscription();
    stopStoreSubscription();
  };

  /**
   * アカウント情報のリスナー破棄
   *
   * @param commit
   * @param dispatch
   */
  const stopAccountSubscription = () => {
    // リスナーの停止
    stopAppSubscribe();
    stopAccountSettingSubscribe();
  };

  /**
   * 店舗情報のリスナー破棄
   *
   * @param commit
   * @param dispatch
   */
  const stopStoreSubscription = () => {
    // リスナーの停止
    stopSettingsSubscribe();
    tablesStore.stopTablesSubscribe();
    messagesStore.stopMessagesSubscribe();
    questionnairesStore.stopQuestionnaireSubscribe();
    staffsStore.stopStaffSubscribe();
  };

  /**
   * ログイン
   *
   * @param obj
   */
  const login = async (obj: { userName: string; password: string }) => {
    const credential = await signInWithEmailAndPassword(auth, obj.userName, obj.password).catch((error) => {
      throw error; // TODO: 適切なエラーをthrowするようにする。
    });

    await initAccount({
      user: credential.user,
    });
    if (!accountSetting.value?.is2ndGenData) {
      await initStore({
        user: credential.user,
      });
    }
  };

  /**
   * ログアウト
   *
   * @param stopSubscription DB購読を停止するかどうか
   */
  const logout = async (stopSubscription = true) => {
    // Firestoreから通知トークンを削除する
    const lastToken = localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.MESSAGING_TOKEN);
    if (lastToken) {
      await removeToken(lastToken, auth.currentUser?.uid ?? '', storeId.value);
      await unregisterSw();
    }
    await auth.signOut().catch((error) => {
      throw error; // TODO: 適切なエラーをthrowするようにする。
    });

    if (stopSubscription) {
      stopAllSubscription();
    }
    resetUser();
    localStorage.removeItem(CommonSettings.WEB_STORAGE_KEY.STORE_ID);
    sessionStorage.removeItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_STORE_ID);
    sessionStorage.removeItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_PASSWORD);
  };

  /**
   * Storeのユーザデータをリセット
   */
  const resetUser = async () => {
    user.value = null;
    isLogin.value = false;
    initAccountTime.value = null;
    initStoreTime.value = null;
    storeId.value = '';
    currentStore.value = null;
  };

  /**
   * 店舗ログイン
   */
  const loginStore = async (obj: { contractId: string; storeId: string; password: string }) => {
    try {
      const res = await api.store<LoignStoreParam, LoignStoreRes>({
        process: 'login',
        apiName: 'store',
        contractId: obj.contractId,
        storeId: obj.storeId,
        password: obj.password,
      });

      const isHeadquarter = res.store.type === storeType.headquarters;

      localStorage.setItem(CommonSettings.WEB_STORAGE_KEY.STORE_ID, res.store.id);
      if (isHeadquarter) {
        sessionStorage.setItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_STORE_ID, res.store.id);
        sessionStorage.setItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_PASSWORD, obj.password);
      }

      await initStore({ user: auth.currentUser });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 店舗切り替え
   */
  const changeStore = async (obj: { contractId: string; storeId: string; password: string }) => {
    try {
      await api.store<LoignStoreParam, LoignStoreRes>({
        process: 'login',
        apiName: 'store',
        contractId: obj.contractId,
        storeId: sessionStorage.getItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_STORE_ID) ?? '',
        password: obj.password,
      });

      localStorage.setItem(CommonSettings.WEB_STORAGE_KEY.STORE_ID, obj.storeId);
      sessionStorage.setItem(CommonSettings.WEB_STORAGE_KEY.HEADQUARTER_PASSWORD, obj.password);

      stopStoreSubscription();
      initStoreTime.value = null;
      await initStore({ user: auth.currentUser });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 再認証処理
   *
   * @param obj
   */
  const reauthentication = async (obj: { password: string }) => {
    if (!user.value?.email) {
      return;
    }

    const credential = EmailAuthProvider.credential(user.value.email, obj.password);
    // 再認証処理
    await reauthenticateWithCredential(user.value, credential);
  };

  /**
   * appの監視を開始
   *
   * @param obj
   */
  const startAppSubscribe = (obj: { appId: string }) => {
    console.log('startSubscribe cApp');

    const unsubscribe = onSnapshot(docs.appRef(obj.appId), (doc) => {
      app.value = doc.exists() ? new App(doc.data() as CApp) : ({} as App);
    });
    appDbUnsubscriber.value = unsubscribe;
  };

  /**
   * cAppの監視を停止
   *
   * @param state
   */
  const stopAppSubscribe = () => {
    console.log('stopSubscribe app');
    if (appDbUnsubscriber.value) {
      appDbUnsubscriber.value();
    }
  };

  /**
   * rSettingsの監視を開始
   *
   * @param obj
   */
  const startSettingsSubscribe = (obj: { contractId: string; storeId: string }) => {
    console.log('startSubscribe r_settings');
    const unsubscribe = onSnapshot(docs.settingDoc(obj.contractId, obj.storeId), async (doc) => {
      if (doc.exists()) {
        const rSettings = doc.data() as RSettings;
        // 画像ファイルの更新
        await Promise.all([
          loadBgAnytimeImage(rSettings.eatinTopView?.bgImage ?? ''),
          loadHeroAnytimeImage(rSettings.eatinTopView?.heroImage ?? ''),
        ]);

        themeColor.value = rSettings.theme?.color ?? null;
        miniAppDarkTheme.value = rSettings.theme?.darkmode ?? false;

        settings.value = new Settings(rSettings);
      }
    });

    dbUnsubscriber.value = unsubscribe;
  };

  /**
   * rSettingsの監視を停止
   *
   * @param state
   */
  const stopSettingsSubscribe = () => {
    console.log('stopSubscribe r_settings');
    if (dbUnsubscriber.value) {
      dbUnsubscriber.value();
    }
  };

  /**
   * rAccountSettingの監視を開始
   *
   * @param contractId
   */
  const startAccountSettingSubscribe = (obj: { contractId: string }) => {
    console.log('startSubscribe r_account_setting');

    // アカウント設定の情報をリッスン
    const unsubscriber = onSnapshot(docs.accountSettingDoc(obj.contractId), async (doc) => {
      if (doc.exists()) {
        const tmpAccountSetting = doc.data() as RAccountSetting;
        // 画像ファイルの更新
        await loadBrandLogoImage(tmpAccountSetting.brandLogoImageUrl);
        await loadMembershipLogoImage(tmpAccountSetting.membershipInfo?.logoImageUrl ?? '');
        await loadMembershipBackgroundImage(
          tmpAccountSetting.membershipInfo?.stampCardSetting?.backGroundImageUrl ?? ''
        );

        accountSetting.value = new AccountSetting(tmpAccountSetting);
      }
    });

    accountSettingDbUnsubscriber.value = unsubscriber;
  };

  /**
   * rAccountSettingの監視を終了
   */
  const stopAccountSettingSubscribe = () => {
    console.log('stopSubscribe r_account_setting');
    if (accountSettingDbUnsubscriber.value) {
      accountSettingDbUnsubscriber.value();
    }
  };
  /**
   * 注文くん（店内）ミニアプリ背景画像の取得
   *
   * @param obj
   */
  const loadBgAnytimeImage = async (imageUrl: string) => {
    try {
      bgAnytimeImage.value = imageUrl ? await getDownloadURL(storageRef(Utils.getImagePath(imageUrl))) : '';
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 注文くん（店内）ミニアプリ背景画像の取得
   *
   * @param obj
   */
  const loadHeroAnytimeImage = async (imageUrl: string) => {
    try {
      heroAnytimeImage.value = imageUrl ? await getDownloadURL(storageRef(Utils.getImagePath(imageUrl))) : '';
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * ブランドロゴ画像の取得
   *
   * @param obj
   */
  const loadBrandLogoImage = async (imageUrl: string) => {
    try {
      brandLogoImage.value = imageUrl ? await getDownloadURL(storageRef(Utils.getImagePath(imageUrl))) : '';
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 会員証のロゴ画像の取得
   * @param imageUrl
   */
  const loadMembershipLogoImage = async (imageUrl: string) => {
    try {
      membershipLogoImage.value = imageUrl ? await getDownloadURL(storageRef(Utils.getImagePath(imageUrl))) : '';
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 会員証のスタンプカード背景画像の取得
   * @param imageUrl
   */
  const loadMembershipBackgroundImage = async (imageUrl: string) => {
    try {
      membershipBackgroundImage.value = imageUrl ? await getDownloadURL(storageRef(Utils.getImagePath(imageUrl))) : '';
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * レストラン情報の更新
   *
   * @param obj
   */
  const updateSettings = async (obj: { contractId: string; params: Partial<RSettings>; newImages?: ImageObject[] }) => {
    try {
      for await (const image of obj.newImages ?? []) {
        // 画像の更新
        if (image.url && image.file) {
          if (image.oldUrl) {
            // 画像の削除
            await Utils.deleteImage({ url: image.oldUrl }).catch((e) => console.error(e));
          }
          // 画像のアップロード
          await Utils.setMenuImage({
            url: image.url,
            imageFile: image.file,
          });
          // obj.params.staffImageUrl = obj.newStaffImage.url; // 更新用オブジェクトにimage_urlをassign
        }

        // 画像を削除する場合
        if (image.oldUrl && !image.url && !image.file) {
          // 画像の削除
          await Utils.deleteImage({
            url: image.oldUrl,
          }).catch((e) => console.error(e));

          // obj.params.staffImageUrl = ''; // 更新用オブジェクトにimage_urlをassign
        }
      }

      await setDoc(docs.settingDoc(obj.contractId, storeId.value), obj.params, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * AccountSettingの更新
   *
   * @param obj
   */
  const updateAccountSetting = async (obj: {
    contractId: string;
    params: Partial<RAccountSetting>;
    newImages?: ImageObject[];
  }) => {
    try {
      for await (const image of obj.newImages ?? []) {
        // 画像の更新
        if (image.url && image.file) {
          if (image.oldUrl) {
            // 画像の削除
            await Utils.deleteImage({ url: image.oldUrl }).catch((e) => console.error(e));
          }
          // 画像のアップロード
          await Utils.setMenuImage({
            url: image.url,
            imageFile: image.file,
          });
        }

        // 画像を削除する場合
        if (image.oldUrl && !image.url && !image.file) {
          // 画像の削除
          await Utils.deleteImage({
            url: image.oldUrl,
          }).catch((e) => console.error(e));
        }
      }

      await setDoc(docs.accountSettingDoc(obj.contractId), obj.params, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メールアドレスの変更
   *
   * @param obj
   */
  const updateEmailInfo = async (obj: { email: string; password: string }) => {
    try {
      if (!user.value || !user.value.email) {
        return;
      }

      const credential = EmailAuthProvider.credential(user.value.email, obj.password);
      // 再認証を行い、成功した場合パスワードを変更する。
      await reauthenticateWithCredential(user.value, credential);
      await updateEmail(user.value, obj.email);
      email.value = obj.email;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * パスワードの変更
   *
   * @param obj
   */
  const updatePasswordInfo = async (obj: { oldPassword: string; newPassword: string }) => {
    try {
      if (!user.value || !user.value.email) {
        return;
      }

      const credential = EmailAuthProvider.credential(user.value.email, obj.oldPassword);
      // 再認証を行い、成功した場合パスワードを変更する。
      await reauthenticateWithCredential(user.value, credential);
      await updatePassword(user.value, obj.newPassword);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * パスワードリセットメールの送信
   *
   * @param obj
   */
  const sendPasswordResetEmailInfo = async (obj: { email: string }) => {
    // メールアドレスの形式が間違っていた場合とメールアドレスが登録されていなかった場合にエラーとなる。
    try {
      await sendPasswordResetEmail(auth, obj.email);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 端末権限を変更
   *
   * @param obj
   */
  const switchPermission = async (obj: { permission: Permission }) => {
    permission.value = obj.permission;
  };

  /**
   * ウェイターテーブルのインポート
   *
   * @param obj
   */
  const importWaiterTable = async (obj: { contractId: string }) => {
    // ウェイターAPI呼び出し
    await api.smaregi<ImportWaiterTableReq, ImportWaiterTableRes>({
      process: 'import_waiter_tables',
      contractId: obj.contractId,
      storeId: storeId.value,
    });
  };

  /**
   * スマレジ端末のインポート
   *
   * @param obj
   */
  const importSmaregiTerminal = async (obj: { contractId: string; storeId: string }) => {
    // スマレジAPI呼び出し
    return await api.smaregi<ImportSmaregiTerminalReq, ImportSmaregiTerminalRes>({
      process: 'import_smaregi_terminal',
      contractId: obj.contractId,
      storeId: storeId.value,
    });
  };
  /**
   * 注文開始パスワード認証処理
   *
   * @param obj
   */
  const userOrderPasswordAuth = async (obj: Omit<PasswordAuthReq, 'process'>): Promise<PasswordAuthRes> => {
    try {
      const res = (await api.userEntry({
        process: 'password_auth',
        contractId: obj.contractId,
        storeId: obj.storeId,
        password: obj.password,
      })) as PasswordAuthRes;

      if (res.settings) {
        settings.value = new Settings(res.settings);
        themeColor.value = res.settings?.theme?.color ?? '';
        miniAppDarkTheme.value = res.settings?.theme?.darkmode ?? false;
      }
      if (res.accountSettings) {
        accountSetting.value = new AccountSetting(res.accountSettings);
        await loadBrandLogoImage(res.accountSettings?.brandLogoImageUrl ?? '');
      }

      return res;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 入店処理
   *
   * @param obj
   */
  const guestEntry = async (obj: Omit<GuestEntryReq, 'process'>): Promise<GuestEntryRes> => {
    try {
      const res = (await api.userEntry({
        ...obj,
        process: 'anytime',
      })) as GuestEntryRes;

      if (res.errorCode === 'ERROR' || !res.accountSettings || !res.settings) {
        return res;
      }

      accountSetting.value = new AccountSetting(res.accountSettings);
      useStripePayment.value = res.stripePayment;
      stripeConnectAccount.value = res.stripePaymentParams.stripeAccountId;
      stripePrevCard.value = res.stripePrevCard;
      useSquarePayment.value = res.squarePayment;
      squareLocationId.value = res.squarePaymentParams.locationId;
      squarePaymentUserInfo.value = res.squarePaymentUserInfo;
      gmoCardInfo.value = res.gmoCardInfo;
      gmoPaymentParams.value = res.gmoPaymentParams;
      useMultilingual.value = res.settings?.useMultilingual ?? false;
      useWaiter.value = res.waiter ?? false;
      lineMiniApp.value = res.settings?.lineMiniApp ?? null;
      settings.value = new Settings(res.settings);
      await Promise.all([
        loadBrandLogoImage(res.accountSettings?.brandLogoImageUrl ?? ''),
        loadMembershipLogoImage(res.accountSettings?.membershipInfo?.logoImageUrl ?? ''),
        loadMembershipBackgroundImage(res.accountSettings?.membershipInfo?.stampCardSetting?.backGroundImageUrl ?? ''),
        loadBgAnytimeImage(res.settings?.eatinTopView?.bgImage ?? ''),
        loadHeroAnytimeImage(res.settings?.eatinTopView?.heroImage ?? ''),
      ]);

      userReceiptJournalItem.value = res.receipts;

      themeColor.value = res.settings?.theme?.color ?? null;
      miniAppDarkTheme.value = res.settings?.theme?.darkmode ?? false;

      // テーブル、商品情報を更新
      if (res.table) {
        tablesStore.setUserTable({ table: res.table });
      }
      if (res.menuCategories) {
        menuCategoriesStore.setMenuCategories({ menuCategories: res.menuCategories });
      }
      if (res.menus) {
        menusStore.setMenus({ menus: res.menus });
        // 画像ファイルの更新
        await menusStore
          .loadMenuImages({
            contractId: obj.contractId,
            menuArray: menusStore.getMenuArray,
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (prioritize(miniAppPaymentMethods.creditCardGmo)) {
        await gmoInitialize();
      }

      return res;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 注文くん（店外）の初期データ取得処理
   *
   * @param obj
   */
  const guestEntryAnywhere = async (p: {
    contractId: string;
    storeId: string;
    lineUserId: string;
    liffAccessToken?: string;
  }): Promise<GuestEntryAnywhereRes> => {
    const res = (await api.userEntry({
      process: 'anywhere',
      contractId: p.contractId,
      storeId: p.storeId,
      lineUserId: p.lineUserId,
      liffAccessToken: p.liffAccessToken,
    })) as GuestEntryAnywhereRes;

    accountSetting.value = new AccountSetting(res.accountSettings);
    settings.value = new Settings(res.settings);
    userReceiptJournalItem.value = res.receipts;
    useStripePayment.value = res.stripePayment;
    stripeConnectAccount.value = res.stripePaymentParams.stripeAccountId;
    stripePrevCard.value = res.stripePrevCard;
    useSonyPayment.value = res.sonyPayment;
    useSonyPaymentCredit.value = res.sonyPaymentCredit;
    useGmoPayment.value = res.gmoPayment;
    useSquarePayment.value = res.squarePayment;
    squareLocationId.value = res.squarePaymentParams.locationId;
    squarePaymentUserInfo.value = res.squarePaymentUserInfo;
    gmoCardInfo.value = res.gmoCardInfo;
    gmoPaymentParams.value = res.gmoPaymentParams;
    useMultilingual.value = res.settings?.useMultilingual ?? false;
    smaregiMembershipConnected.value = res.smaregiMembershipConnected;
    publicHoliday.value = res.publicHolidays;
    loadBrandLogoImage(res.accountSettings.brandLogoImageUrl);
    loadMembershipLogoImage(res.accountSettings.membershipInfo?.logoImageUrl ?? '');
    loadMembershipBackgroundImage(res.accountSettings.membershipInfo?.stampCardSetting?.backGroundImageUrl ?? '');
    lineMiniApp.value = res.settings.lineMiniApp ?? null;

    themeColor.value = res.settings.theme?.color ?? null;
    miniAppDarkTheme.value = res.settings?.theme?.darkmode ?? false;

    if (res.menuCategories) {
      menuCategoriesStore.setMenuCategories({ menuCategories: res.menuCategories });
    }
    if (res.menus) {
      menusStore.setMenus({ menus: res.menus });
      // 画像ファイルの更新
      await menusStore
        .loadMenuImages({
          contractId: p.contractId,
          menuArray: menusStore.getMenuArray,
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (prioritize(miniAppPaymentMethods.creditCardGmo)) {
      await gmoInitialize();
    }

    return res;
  };

  /**
   * 通販くんの初期データ取得処理
   *
   * @param obj
   */
  const guestEntryEc = async (p: {
    contractId: string;
    storeId: string;
    lineUserId: string;
    liffAccessToken?: string;
  }): Promise<GuestEntryEcRes> => {
    const res = (await api.userEntry({
      process: 'ec',
      contractId: p.contractId,
      storeId: p.storeId,
      lineUserId: p.lineUserId,
      liffAccessToken: p.liffAccessToken,
    })) as GuestEntryEcRes;

    accountSetting.value = new AccountSetting(res.accountSettings);
    settings.value = new Settings(res.settings);
    ecOrderHistory.value = res.history;
    useStripePayment.value = res.stripePayment;
    stripeConnectAccount.value = res.stripePaymentParams.stripeAccountId;
    stripePrevCard.value = res.stripePrevCard;
    useSonyPayment.value = res.sonyPayment;
    useSonyPaymentCredit.value = res.sonyPaymentCredit;
    useGmoPayment.value = res.gmoPayment;
    loadBrandLogoImage(res.accountSettings.brandLogoImageUrl);
    loadMembershipLogoImage(res.accountSettings.membershipInfo?.logoImageUrl ?? '');
    loadMembershipBackgroundImage(res.accountSettings.membershipInfo?.stampCardSetting?.backGroundImageUrl ?? '');
    lineMiniApp.value = res.settings.lineMiniApp ?? null;

    return res;
  };

  /**
   * 順番待ち子ちゃんの初期データ取得処理
   *
   * @param obj
   */
  const guestEntryWaiting = async (p: Omit<GuestEntryWaitingReq, 'process'>): Promise<GuestEntryWaitingRes> => {
    const res = (await api.userEntry({
      ...p,
      process: 'waiting',
    })) as GuestEntryWaitingRes;

    settings.value = new Settings(res.settings);
    accountSetting.value = new AccountSetting(res.accountSetting);
    customer.value = new Customer(res.customer ?? ({} as MCustomer));
    loadBrandLogoImage(res.accountSetting.brandLogoImageUrl);
    loadMembershipLogoImage(res.accountSetting.membershipInfo?.logoImageUrl ?? '');
    loadMembershipBackgroundImage(res.accountSetting.membershipInfo?.stampCardSetting?.backGroundImageUrl ?? '');
    lineMiniApp.value = res.settings.lineMiniApp ?? null;
    storeId.value = p.storeId;

    return res;
  };

  /**
   * 会員証の初期データ取得処理
   *
   * @param obj
   */
  const guestEntryMembership = async (
    p: Omit<GuestEntryMembershipReq, 'process'>
  ): Promise<GuestEntryMembershipRes> => {
    const res = (await api.userEntry({
      ...p,
      process: 'membership',
    })) as GuestEntryMembershipRes;

    settings.value = new Settings(res.settings);
    accountSetting.value = new AccountSetting(res.accountSetting);
    customer.value = new Customer({
      ...(res.customer ?? {}),
      lineUserId: res.customer?.lineUserId ?? p.lineUserId,
      membershipNo: res.customer?.membershipNo ?? '',
      displayName: res.customer?.displayName ?? '',
      pictureUrl: res.customer?.pictureUrl ?? '',
      status: res.customer?.status ?? 'follow',
      stampCard:
        res.customer?.stampCard?.map((val) => ({
          transactionId: val.transactionId,
          storeId: val.storeId ?? '',
          storeName: val.storeName ?? '',
          earnedDate: new Date(val.earnedDate),
          expireDate: new Date(val.expireDate),
        })) ?? [],
      coupons:
        res.customer?.coupons?.map((val) => ({
          id: val.id,
          couponId: val.couponId,
          serialNumber: val.serialNumber,
          useType: val.useType ?? useType.oneTime,
          name: val.name,
          detail: val.detail,
          awardType: val.awardType,
          earnedDate: new Date(val.earnedDate),
          ...(val.startDate ? { startDate: new Date(val.startDate) } : {}),
          ...(val.expireDate ? { expireDate: new Date(val.expireDate) } : {}),
          used: val.used,
          dataSource: val.dataSource,
        })) ?? [],
      stampCardExpireDate: res.customer?.stampCardExpireDate ? new Date(res.customer.stampCardExpireDate) : null,
      pointExpireDate: res.customer?.pointExpireDate ? new Date(res.customer.pointExpireDate) : null,
      birthday: res.customer?.birthday ? new Date(res.customer.birthday) : null,
      nextBooking: res.customer?.nextBooking ? new Date(res.customer.nextBooking) : null,
      lastUsedAt: res.customer?.lastUsedAt ? new Date(res.customer.lastUsedAt) : null,
      lastUpdateAt: res.customer?.lastUpdateAt ? new Date(res.customer.lastUpdateAt) : null,
      lastLoginAt: res.customer?.lastLoginAt ? new Date(res.customer.lastLoginAt) : null,
    });
    currentRank.value = new MembersRank(res.currentRank);
    nextRank.value = new MembersRank(res.nextRank);
    customerTotalAmount.value = res.totalAmount;
    customerTotalCount.value = res.totalCount;
    customerPoint.value = res.customer?.point ?? 0;
    loadBrandLogoImage(res.accountSetting.brandLogoImageUrl);
    loadMembershipLogoImage(res.accountSetting.membershipInfo?.logoImageUrl ?? '');
    loadMembershipBackgroundImage(res.accountSetting.membershipInfo?.stampCardSetting?.backGroundImageUrl ?? '');
    lineMiniApp.value = res.settings.lineMiniApp ?? null;
    storeId.value = p.storeId;

    return res;
  };

  /**
   * 順番待ちからモバイルオーダーへのチェックイン処理
   *
   * @param obj
   */
  const waitingToMobileOrder = async (obj: Omit<GuestEntryReq, 'process'>): Promise<GuestEntryRes> => {
    try {
      return api.userEntry({
        ...obj,
        process: 'anytime',
      }) as Promise<GuestEntryRes>;
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * POS連携
   *
   * @param obj
   */
  const connectPos = async (obj: ConnectPosReq): Promise<ConnectPosRes> => {
    try {
      return api.connectPos(obj);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * Messaging API 連携
   *
   * @param obj
   */
  const attachMessagingApi = async (obj: Omit<AttachMessagingApiReq, 'process'>): Promise<AttachLineRes> => {
    try {
      return api.lineConnect<AttachMessagingApiReq, AttachLineRes>({ ...obj, process: 'attach-messaging-api' });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * Module Channel 連携
   *
   * @param obj
   */
  const attachModuleChannel = async (obj: Omit<AttachModuleChannelReq, 'process'>): Promise<AttachLineRes> => {
    try {
      return api.lineConnect<AttachModuleChannelReq, AttachLineRes>({ ...obj, process: 'attach-module-channel' });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * LINE連携解除
   *
   * @param obj
   */
  const detachLine = async (obj: Omit<DetachLineReq, 'process'>): Promise<DetachLineRes> => {
    try {
      return api.lineConnect<DetachLineReq, DetachLineRes>({ ...obj, process: 'detach' });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 解約
   *
   * @param obj
   */
  const cancelSubscription = async (obj: CancelSubscriptionReq): Promise<CancelSubscriptionRes> => {
    try {
      return api.cancelSubscription(obj);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * Stripe 連結
   *
   * @param obj
   */
  const stripeConnect = async (obj: Omit<StripeConnectReq, 'process'>): Promise<StripeConnectRes> => {
    try {
      return api.stripe<StripeConnectReq, StripeConnectRes>({ ...obj, process: 'connect' });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * Square 連結
   *
   * @param obj
   */
  const squareConnect = async (obj: Omit<SquareConnectReq, 'process'>): Promise<SquareConnectRes> => {
    try {
      return api.square<SquareConnectReq, SquareConnectRes>({ ...obj, process: 'connect' });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * Square 店舗リスト取得
   *
   * @param obj
   * @returns res
   */
  const squareLocations = async (obj: Omit<SquareListLocationsReq, 'process'>): Promise<SquareListLocationsRes> => {
    try {
      return api.square<SquareListLocationsReq, SquareListLocationsRes>({ ...obj, process: 'list-locations' });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * Square locationId設定の更新
   *
   * @param obj
   */
  const updateSquareLocationId = async (obj: { contractId: string; locationId: string }) => {
    try {
      const updateParam: Partial<RSettings> = {
        squareLocationId: obj.locationId,
      };

      // 更新
      await setDoc(docs.settingDoc(obj.contractId, storeId.value), updateParam, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ポジションの更新
   *
   * @param obj
   */
  const updateKitchen = async (obj: {
    contractId: string;
    settings: Settings;
    kitchen: {
      kitchenNo: string;
      sound: string;
      name: string;
    };
  }) => {
    try {
      let kitchenNo: string = obj.kitchen.kitchenNo;

      const ids: string[] = Object.keys(obj.settings.kitchen);

      if (!obj.kitchen.kitchenNo) {
        kitchenNo = `${ids.length + 1}`;
        for (let i = ids.length; i > 0; i--) {
          if (ids.indexOf(i.toString()) < 0) {
            kitchenNo = `${i}`;
          }
        }
      }

      const kitchen: { [key: string]: Kitchen } = obj.settings.kitchen[kitchenNo]
        ? {
            [kitchenNo]: {
              name: obj.kitchen.name,
              sound: obj.kitchen.sound,
              sort_key: obj.settings.kitchen[kitchenNo].sort_key,
            },
          }
        : {
            [kitchenNo]: {
              name: obj.kitchen.name,
              sound: obj.kitchen.sound,
              sort_key: ids.length,
            },
          };

      const updateParam: Partial<RSettings> = {
        kitchen: { ...obj.settings.kitchen, ...kitchen },
      };

      // 更新
      await setDoc(docs.settingDoc(obj.contractId, storeId.value), updateParam, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * LINEミニアプリ設定の更新
   *
   * @param obj
   */
  const updateLineMiniApp = async (obj: {
    contractId: string;
    channelId: string;
    channelSecret: string;
    liffId: string;
  }) => {
    try {
      const updateParam: Partial<RSettings> = {
        lineMiniApp:
          obj.channelId && obj.channelSecret && obj.liffId
            ? {
                anywhere: {
                  channelId: obj.channelId,
                  channelSecret: obj.channelSecret,
                  liffId: obj.liffId,
                  disabled: false,
                },
              }
            : null,
      };

      // 更新
      await setDoc(docs.settingDoc(obj.contractId, storeId.value), updateParam, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   *Stripe連携設定削除
   *
   * @param obj
   */
  const deleteStripeConnection = async (obj: { contractId: string }) => {
    try {
      const updateParam: Partial<RAccountSetting> = {
        stripeConnect: null,
      };

      // 更新
      await setDoc(docs.accountSettingDoc(obj.contractId), updateParam, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ソニーペイメント設定の更新
   *
   * @param obj
   */
  const updateSonyPayment = async (obj: {
    contractId: string;
    merchantId: string;
    password: string;
    encryptIv: string;
    encryptKey: string;
  }) => {
    try {
      const updateParam: Partial<RAccountSetting> = {
        sonyPayment:
          obj.merchantId && obj.password
            ? {
                merchantId: obj.merchantId,
                password: obj.password,
                ...(obj.encryptIv && obj.encryptKey
                  ? {
                      paypay: {
                        encryptIv: obj.encryptIv,
                        encryptKey: obj.encryptKey,
                      },
                    }
                  : {}),
              }
            : null,
      };

      // 更新
      await setDoc(docs.accountSettingDoc(obj.contractId), updateParam, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * GMOペイメント設定の更新
   *
   * @param obj
   */
  const updateGmoPayment = async (obj: {
    contractId: string;
    siteId: string;
    sitePassword: string;
    shopId: string;
    shopPassword: string;
    apiKey: string;
    publicKey: string;
  }) => {
    try {
      const updateParam: Partial<RAccountSetting> = {
        gmoPayment:
          obj.siteId && obj.sitePassword && obj.shopId && obj.shopPassword && obj.apiKey && obj.publicKey
            ? {
                siteId: obj.siteId,
                sitePassword: obj.sitePassword,
                shopId: obj.shopId,
                shopPassword: obj.shopPassword,
                apiKey: obj.apiKey,
                publicKey: obj.publicKey,
              }
            : null,
      };

      // 更新
      await setDoc(docs.accountSettingDoc(obj.contractId), updateParam, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /*
   * board連携設定の更新
   *
   * @param obj
   */
  const updateBoard = async (obj: { contractId: string; apiKey: string; apiToken: string; managerId: string }) => {
    try {
      const updateParam: Partial<RAccountSetting> = {
        board:
          obj.apiKey && obj.apiToken && obj.managerId
            ? {
                apiKey: obj.apiKey,
                apiToken: obj.apiToken,
                managerId: obj.managerId,
              }
            : null,
      };

      // 更新
      await setDoc(docs.accountSettingDoc(obj.contractId), updateParam, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   *Square連携設定削除
   *
   * @param obj
   */
  const deleteSquareConnection = async (obj: { contractId: string }) => {
    try {
      return api.square<SquareDisconnectReq, SquareDisconnectRes>({
        process: 'disconnect',
        contractId: obj.contractId,
        storeId: storeId.value,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 順番待ち設定の更新
   *
   * @param obj
   */
  const updateWaitingInfo = async (obj: { contractId: string; params: Partial<WaitingInfo> }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-types
      type DeepPartial<T> = T extends object
        ? {
            [P in keyof T]?: DeepPartial<T[P]>;
          }
        : T;

      const updateParam: DeepPartial<RSettings> = {
        waitingInfo: obj.params,
      };

      // 更新
      await setDoc(docs.settingDoc(obj.contractId, storeId.value), updateParam, { merge: true });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ポジションの削除
   *
   * @param obj
   */
  const deleteKitchen = async (obj: { contractId: string; settings: Settings; kitchenNo: string }) => {
    try {
      const kitchen = obj.settings.kitchen;

      if (!kitchen[obj.kitchenNo]) {
        throw new DataConflict();
      }

      // ポジションに紐づくメニュー・注文伝票が存在するかどうか確認
      const menus: RMenu[] = await api
        .getMenu<MenuReq, MenuRes>({ process: 'menu', contractId: obj.contractId, storeId: storeId.value })
        .then((res) => res.menu);

      const presentKitchenMenus = menus.filter(
        (val) =>
          (val.positions ? val.positions.includes(obj.kitchenNo) : val.kitchen_no === obj.kitchenNo) ||
          val.transferKitchen1?.kitchenNo === obj.kitchenNo ||
          val.transferKitchen2?.kitchenNo === obj.kitchenNo
      );

      if (presentKitchenMenus.length > 0) {
        throw new ExistRelatedData(
          '下記の商品',
          presentKitchenMenus
            .map((val) => val.name)
            .reduce((a, b) => {
              return a + ',' + b;
            })
        );
      }

      // 対象のポジションを削除
      delete kitchen[obj.kitchenNo];

      // sort_keyの振り直し
      Object.keys(kitchen)
        .map((key: string): { key: string; data: Kitchen } => ({
          key: key,
          data: kitchen[key],
        }))
        .sort((m1, m2) => {
          // sortKeyの順番で配列をソート
          if (m1.data.sort_key < m2.data.sort_key) {
            return -1;
          }
          if (m1.data.sort_key > m2.data.sort_key) {
            return 1;
          }
          return 0;
        })
        .forEach((obj, index) => {
          kitchen[obj.key].sort_key = index;
        });

      // 対象ポジション削除済みの更新値
      const updating: Pick<RSettings, 'kitchen'> = {
        kitchen: kitchen,
      };

      // ポジションを更新
      await updateDoc(docs.settingDoc(obj.contractId, storeId.value), updating);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ポジションの並び替え
   *
   * @param obj
   */
  const sortKitchen = async (obj: { contractId: string; settings: Settings; sortedArray: KitchenModel[] }) => {
    try {
      const kitchen = obj.settings.kitchen;

      // キーの配列の長さとカテゴリ数が一致しない場合は失敗
      if (Object.keys(kitchen).length === obj.sortedArray.length) {
        obj.sortedArray.forEach((obj, index) => (kitchen[obj.key].sort_key = index)); // ポジションのキーに対して順に番号を振っていく
      } else {
        throw new DataConflict();
      }

      // 対象ポジション削除済みの更新値
      const updating: Pick<RSettings, 'kitchen'> = {
        kitchen: kitchen,
      };

      // ポジションを更新
      await updateDoc(docs.settingDoc(obj.contractId, storeId.value), updating);
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * スタッフを更新
   *
   * @param obj
   */
  const setStaffId = (obj: { staffId: string }) => {
    staff.value = obj.staffId;
  };

  /**
   * 多言語用の表示言語を保持
   *
   * @param obj
   */
  const setDisplayLanguage = (obj: { language: string }) => {
    language.value = obj.language;
  };

  const prioritize = (method: miniAppPaymentMethods) => {
    switch (method) {
      case miniAppPaymentMethods.creditCard:
        // Stripe
        return !useGmoPayment.value && useStripePayment.value;
      case miniAppPaymentMethods.creditCardSquare:
        // Square
        return !useGmoPayment.value && !useStripePayment.value && useSquarePayment.value;
      case miniAppPaymentMethods.creditCardSp:
        // SonyPayment Credit
        return !useGmoPayment.value && !useStripePayment.value && useSonyPaymentCredit.value && !useSquarePayment.value;
      case miniAppPaymentMethods.paypay:
        // SonyPayment PayPay
        return !useGmoPayment.value && useSonyPayment.value;
      case miniAppPaymentMethods.creditCardGmo:
        // GMO
        return useGmoPayment.value;
    }
    return false;
  };

  const gmoInitialize = async () => {
    if (!gmoPaymentParams.value) {
      return;
    }

    const mulpay = await loadMulpay(
      gmoPaymentParams.value.apiKey!,
      gmoPaymentParams.value.publicKey!,
      {},
      true,
      process.env.VUE_APP_MODE === 'production'
    );

    if (!mulpay) {
      return;
    }

    const elements = mulpay.createElements();
    // カード情報入力フォームを作成する
    const cardInputStyle: MultiPaymentElementStyle = {
      base: {
        fontFamily: "'Noto Sans Japanese', sans-serif",
        color: miniAppDarkTheme.value ? 'rgb(255, 255, 255)' : 'rgba(0, 0, 0, 0.87)',
        backgroundColor: miniAppDarkTheme.value ? 'rgb(33, 33, 33)' : 'rgb(255, 255, 255)',
        caretColor: '#198FCC',
        lineHeight: '28px',
        '::placeholder': {
          color: miniAppDarkTheme.value ? 'rgba(255, 255, 255, 0.54)' : 'rgba(0, 0, 0, 0.4)',
        },
      },
      invalid: {
        color: '#9e2146',
      },
      focus: {},
    };

    const cardNumberInputElement = elements.create('cardNumber', {
      style: cardInputStyle,
      placeholder: '0000 0000 0000 0000',
    });
    const year = Utils.last2YearDigit(new Date());
    const expiryInputElement = elements.create('cardExpiry', { style: cardInputStyle, placeholder: `12/${year}` });
    const cvcInputElement = elements.create('cardCvc', { style: cardInputStyle, placeholder: '000' });
    // カード情報入力フォームのラッパー要素を配列に格納する
    const mulPayFormElements = [cardNumberInputElement, expiryInputElement, cvcInputElement];

    gmoMulpay.value = {
      mulpay: mulpay,
      mulPayFormElements: mulPayFormElements,
    };
  };

  return {
    ...computables,
    setStoreId,
    initAccount,
    initStore,
    initUserOrder,
    stopAllSubscription,
    stopAccountSubscription,
    stopStoreSubscription,
    login,
    logout,
    resetUser,
    loginStore,
    changeStore,
    reauthentication,
    startAppSubscribe,
    stopAppSubscribe,
    startSettingsSubscribe,
    stopSettingsSubscribe,
    startAccountSettingSubscribe,
    stopAccountSettingSubscribe,
    loadBgAnytimeImage,
    loadHeroAnytimeImage,
    loadBrandLogoImage,
    loadMembershipLogoImage,
    loadMembershipBackgroundImage,
    updateSettings,
    updateAccountSetting,
    updateEmailInfo,
    updatePasswordInfo,
    sendPasswordResetEmailInfo,
    switchPermission,
    importWaiterTable,
    importSmaregiTerminal,
    userOrderPasswordAuth,
    guestEntry,
    guestEntryAnywhere,
    guestEntryEc,
    guestEntryWaiting,
    guestEntryMembership,
    waitingToMobileOrder,
    connectPos,
    attachMessagingApi,
    attachModuleChannel,
    detachLine,
    cancelSubscription,
    stripeConnect,
    squareConnect,
    squareLocations,
    updateSquareLocationId,
    updateKitchen,
    updateLineMiniApp,
    deleteStripeConnection,
    updateSonyPayment,
    updateGmoPayment,
    updateBoard,
    deleteSquareConnection,
    updateWaitingInfo,
    deleteKitchen,
    sortKitchen,
    setStaffId,
    setDisplayLanguage,
  };
});
