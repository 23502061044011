<template>
  <v-app>
    <!-- ログイン済みの場合にメニューを表示 -->
    <menu-bar
      v-if="alreadyLogin && alreadyStoreLogin"
      :pageTitle="pageTitle"
      :pageOrder="pageOrder"
      :appVersion="appVersion"
      @onPushItem="pageTransition"
      @onPushLogout="onPushLogout"
      @error="errorCapture"
    />
    <v-main v-if="alreadyLogin" :style="{ background: $vuetify.theme.themes.light.variables.background }">
      <router-view @error="errorCapture"></router-view>
    </v-main>
    <router-view v-if="!alreadyLogin" @error="errorCapture"></router-view>
    <!-- Dialog -->
    <error-dialog ref="errorDialog" />
    <loading-dialog ref="loadingDialog" :display="isLoading" :message="loadingMessage" />
    <password-dialog
      ref="passwordDialog"
      :message="'この画面へ遷移するためにはパスワードの入力が必要です。'"
      @onPushEnterBtn="onPushPasswordDialogEnterBtn"
    />
    <confirmation-dialog
      ref="logoutConfirmationDialog"
      :title="'ログアウト'"
      :message="'ログアウトしてもよろしいですか?'"
      @onPushEnterBtn="onPushLogOutConfirmationDialogEnterBtn"
    />
  </v-app>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useFormattable } from '@/common/composables/useFormattable';
import { useAppStore } from '@/stores/app';
import { EnvConfig } from '@/common/env-config';
import { useUsersStore } from '@/stores/users';
import * as Pages from '@/router/index';
import MenuBar from '@/components/organisms/MenuBar.vue';
import ErrorDialog from '@/components/molecules/ErrorDialog.vue';
import PasswordDialog, { PasswordDialogEnterBtnParams } from '@/components/molecules/PasswordDialog.vue';
import LoadingDialog from '@/components/molecules/LoadingDialog.vue';
import ConfirmationDialog from '@/components/molecules/ConfirmationDialog.vue';
import { useRoute, useRouter } from 'vue-router';

const { accountSetting, contractId, storeId } = useFormattable();
const appStore = useAppStore();
const userStore = useUsersStore();
const router = useRouter();
const route = useRoute();

const pageTitle = ref('');
const pageOrder = Pages.pageOrder;
const appVersion = EnvConfig.app.APP_VERSION || '';

// ステート管理
const alreadyLogin = computed(() => userStore.getLogin);
const alreadyStoreLogin = computed(() => userStore.getStoreLogin);
const isLoading = computed(() => appStore.getIsLoading);
const loadingMessage = computed(() => appStore.getLoadingMessage);

// ダイアログの `ref`
const errorDialog = ref<InstanceType<typeof ErrorDialog> | null>(null);
const passwordDialog = ref<InstanceType<typeof PasswordDialog> | null>(null);
const logoutConfirmationDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

// ルート変更時のページタイトル更新
watch(
  () => route.name,
  (name) => {
    pageTitle.value = (name as string) || '';
  }
);

/** ページ遷移処理 */
const pageTransition = async (page) => {
  if (page.requireAuthorization && !accountSetting.value.passwordLockDisabled) {
    passwordDialog.value?.open(page.route);
  } else if (page.openOtherWindow) {
    window.open(`${page.route}?contractId=${contractId.value}&storeId=${storeId.value}`, '_blank');
  } else if (page.route !== window.location.pathname) {
    // pageTitleを更新
    pageTitle.value = page.name;
    await router.push(page.route);
  }
};

/** パスワード認証 */
const onPushPasswordDialogEnterBtn = async (params: PasswordDialogEnterBtnParams) => {
  try {
    appStore.startLoading();
    await userStore.reauthentication(params);
    await router.push(params.data);
  } catch (error) {
    errorCapture(error);
  } finally {
    appStore.stopLoading();
  }
};

/** ログアウト処理 */
const onPushLogout = () => {
  logoutConfirmationDialog.value?.open();
};

const onPushLogOutConfirmationDialogEnterBtn = async () => {
  try {
    appStore.startLoading();
    await userStore.logout();
    await router.push('/login');
  } catch (error) {
    errorCapture(error);
  } finally {
    appStore.stopLoading();
  }
};

/** エラーハンドリング */
const errorCapture = (error) => {
  console.error(error);
  errorDialog.value?.open({
    message: error.message || 'エラーが発生しました。',
  });
};

/** ピンチズームの無効化 */
const disablePinch = (event: TouchEvent) => {
  if (event.touches.length >= 2) {
    event.preventDefault();
  }
};

/** イベントリスナーの設定 */
onMounted(() => {
  document.addEventListener('touchstart', disablePinch, { passive: false });

  // ネットワークの監視
  setInterval(() => {
    if (!navigator.onLine) {
      errorDialog.value?.open({
        message: 'インターネットに接続されていません。',
      });
    }
  }, 10000);
});

onUnmounted(() => {
  document.removeEventListener('touchstart', disablePinch);
});
</script>

<style>
html {
  touch-action: manipulation;
  overscroll-behavior-y: contain;
}
body {
  box-sizing: border-box;
  -webkit-touch-callout: none;
}
</style>
