import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import { Staff } from '@/store/models/staff';

// emit

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectStaffDialog',
  emits: ['changeStaff'],
  setup(__props, { expose: __expose, emit: __emit }) {

const emit = __emit;

// Data
const display = ref(false);
const selectedStaffId = ref('');
const selectStaffs = ref<{ text: string; value: string }[]>([{ text: '選択解除', value: '' }]);

// Computed

// Methods
const open = (obj: { staffId: string; staffList: Staff[] }) => {
  display.value = true;
  selectedStaffId.value = obj.staffId;
  selectStaffs.value = [
    ...obj.staffList.map((staff) => ({ text: staff.params.name, value: staff.id })),
    { text: '選択解除', value: '' },
  ];
};

const close = () => {
  display.value = false;
};

const changeStaff = () => {
  emit('changeStaff', selectedStaffId.value);
  close();
};

// Expose
__expose({ open, close });

// LifeCycle Hooks

return (_ctx: any,_cache: any) => {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: display.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((display).value = $event)),
    "max-width": "500px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { title: "スタッフ選択" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, { class: "text-center py-1" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: selectedStaffId.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedStaffId).value = $event)),
                items: selectStaffs.value,
                "item-title": "text",
                "item-value": "value",
                variant: "outlined",
                density: "compact",
                class: "body"
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        block: "",
                        variant: "outlined",
                        onClick: close
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("閉じる")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        block: "",
                        color: "primary",
                        variant: "elevated",
                        onClick: changeStaff
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("設定")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})