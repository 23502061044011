import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useFormattable } from '@/common/composables/useFormattable';
import { useAppStore } from '@/stores/app';
import { EnvConfig } from '@/common/env-config';
import { useUsersStore } from '@/stores/users';
import * as Pages from '@/router/index';
import MenuBar from '@/components/organisms/MenuBar.vue';
import ErrorDialog from '@/components/molecules/ErrorDialog.vue';
import PasswordDialog, { PasswordDialogEnterBtnParams } from '@/components/molecules/PasswordDialog.vue';
import LoadingDialog from '@/components/molecules/LoadingDialog.vue';
import ConfirmationDialog from '@/components/molecules/ConfirmationDialog.vue';
import { useRoute, useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { accountSetting, contractId, storeId } = useFormattable();
const appStore = useAppStore();
const userStore = useUsersStore();
const router = useRouter();
const route = useRoute();

const pageTitle = ref('');
const pageOrder = Pages.pageOrder;
const appVersion = EnvConfig.app.APP_VERSION || '';

// ステート管理
const alreadyLogin = computed(() => userStore.getLogin);
const alreadyStoreLogin = computed(() => userStore.getStoreLogin);
const isLoading = computed(() => appStore.getIsLoading);
const loadingMessage = computed(() => appStore.getLoadingMessage);

// ダイアログの `ref`
const errorDialog = ref<InstanceType<typeof ErrorDialog> | null>(null);
const passwordDialog = ref<InstanceType<typeof PasswordDialog> | null>(null);
const logoutConfirmationDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

// ルート変更時のページタイトル更新
watch(
  () => route.name,
  (name) => {
    pageTitle.value = (name as string) || '';
  }
);

/** ページ遷移処理 */
const pageTransition = async (page) => {
  if (page.requireAuthorization && !accountSetting.value.passwordLockDisabled) {
    passwordDialog.value?.open(page.route);
  } else if (page.openOtherWindow) {
    window.open(`${page.route}?contractId=${contractId.value}&storeId=${storeId.value}`, '_blank');
  } else if (page.route !== window.location.pathname) {
    // pageTitleを更新
    pageTitle.value = page.name;
    await router.push(page.route);
  }
};

/** パスワード認証 */
const onPushPasswordDialogEnterBtn = async (params: PasswordDialogEnterBtnParams) => {
  try {
    appStore.startLoading();
    await userStore.reauthentication(params);
    await router.push(params.data);
  } catch (error) {
    errorCapture(error);
  } finally {
    appStore.stopLoading();
  }
};

/** ログアウト処理 */
const onPushLogout = () => {
  logoutConfirmationDialog.value?.open();
};

const onPushLogOutConfirmationDialogEnterBtn = async () => {
  try {
    appStore.startLoading();
    await userStore.logout();
    await router.push('/login');
  } catch (error) {
    errorCapture(error);
  } finally {
    appStore.stopLoading();
  }
};

/** エラーハンドリング */
const errorCapture = (error) => {
  console.error(error);
  errorDialog.value?.open({
    message: error.message || 'エラーが発生しました。',
  });
};

/** ピンチズームの無効化 */
const disablePinch = (event: TouchEvent) => {
  if (event.touches.length >= 2) {
    event.preventDefault();
  }
};

/** イベントリスナーの設定 */
onMounted(() => {
  document.addEventListener('touchstart', disablePinch, { passive: false });

  // ネットワークの監視
  setInterval(() => {
    if (!navigator.onLine) {
      errorDialog.value?.open({
        message: 'インターネットに接続されていません。',
      });
    }
  }, 10000);
});

onUnmounted(() => {
  document.removeEventListener('touchstart', disablePinch);
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (alreadyLogin.value && alreadyStoreLogin.value)
        ? (_openBlock(), _createBlock(MenuBar, {
            key: 0,
            pageTitle: pageTitle.value,
            pageOrder: _unref(pageOrder),
            appVersion: _unref(appVersion),
            onOnPushItem: pageTransition,
            onOnPushLogout: onPushLogout,
            onError: errorCapture
          }, null, 8, ["pageTitle", "pageOrder", "appVersion"]))
        : _createCommentVNode("", true),
      (alreadyLogin.value)
        ? (_openBlock(), _createBlock(_component_v_main, {
            key: 1,
            style: _normalizeStyle({ background: _ctx.$vuetify.theme.themes.light.variables.background })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, { onError: errorCapture })
            ]),
            _: 1
          }, 8, ["style"]))
        : _createCommentVNode("", true),
      (!alreadyLogin.value)
        ? (_openBlock(), _createBlock(_component_router_view, {
            key: 2,
            onError: errorCapture
          }))
        : _createCommentVNode("", true),
      _createVNode(ErrorDialog, {
        ref_key: "errorDialog",
        ref: errorDialog
      }, null, 512),
      _createVNode(LoadingDialog, {
        ref: "loadingDialog",
        display: isLoading.value,
        message: loadingMessage.value
      }, null, 8, ["display", "message"]),
      _createVNode(PasswordDialog, {
        ref_key: "passwordDialog",
        ref: passwordDialog,
        message: 'この画面へ遷移するためにはパスワードの入力が必要です。',
        onOnPushEnterBtn: onPushPasswordDialogEnterBtn
      }, null, 512),
      _createVNode(ConfirmationDialog, {
        ref_key: "logoutConfirmationDialog",
        ref: logoutConfirmationDialog,
        title: 'ログアウト',
        message: 'ログアウトしてもよろしいですか?',
        onOnPushEnterBtn: onPushLogOutConfirmationDialogEnterBtn
      }, null, 512)
    ]),
    _: 1
  }))
}
}

})